// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
}

// Reverte o comportamento incluído em https://github.com/twbs/bootstrap/pull/36168
// scss-docs-start btn-variant-loops
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @if $color == "light" {
      @include button-variant(
        $value,
        $value,
        $hover-background: mix-blend-softlight(#222, $value),
        $hover-border: mix-blend-softlight(#222, $value),
        $active-background: mix-blend-softlight(#222, $value),
        $active-border: mix-blend-softlight(#222, $value)
      );
    } @else if $color == "dark" {
      @include button-variant(
        $value,
        $value,
        $hover-background: mix-blend-softlight(#222, $value),
        $hover-border: mix-blend-softlight(#222, $value),
        $active-background: mix-blend-softlight(#222, $value),
        $active-border: mix-blend-softlight(#222, $value)
      );
    } @else {
      @include button-variant($value, $value);
    }
  }
}
