@use 'sass:math';

// Centralx Bootstrap Theme
// -
// Este arquivo contém valores que sobrescrevem os padrões declarados
// pelo '_variables.scss' do Bootstrap 5. 
// Valores com a informação '!default' não tiveram seu valor alterado.

// Funções Bootstrap
@import "../node_modules/bootstrap/scss/functions";

// Funções Centralx
@import 'functions';

// Fontes
$font-weight-semibold: 600 !default;

// Cores para escala de cinzas
$white:    #fff !default;
$gray-100: #F7F7F9;
$gray-200: #E9EAEE;
$gray-300: #DDE0E8;
$gray-400: #CBCFDA;
$gray-500: #ABB0C0;
$gray-600: #697081;
$gray-700: #494E58;
$gray-800: #37393E;
$gray-900: #212329;
$black:    #000000;
/* $bat-black: #1a1c1e; */
// -

/* $body-bg-dark: $bat-black; */

// Paleta cores
$red:     #E74C3C;
$green:   #1ABC9C;
$cyan:    #0099CC;
$purple:  #9B59B6;
$pink:    #ED3895;
// -

// Paleta comum
$dark:            $black;
$gray-mediumdark: $gray-600;
$gray-medium:     $gray-400;
$gray-light:      $gray-300;
$gray-lighter:    $gray-100;
// -

// Paleta HiDoctor
$blue:            #004E96;
$blue-mediumdark: #01386D;
$blue-dark:       #012549;
$orange:          #FF9900;
$gold:            #EDCD6C;
$orange-dark:     #FF8000;
$gold-dark:       #C3A559;
// -

// Paleta HiNET
$blue-app:           #526C83;
$blue-desktop:       #B2C0D0;
$blue-desktop-light: #DFE7F0;
$gray:               #CCCCCC;
// -

// Outras marcas
$twitter:  #439BD5;
$facebook: #3D5B9A;
$whatsapp: #128C7E;
// -

// Map de cores
$colors: (
  "red":                $red,
  "green":              $green,
  "cyan":               $cyan,
  "purple":             $purple,
  "pink":               $pink,
  "dark":               $dark,
  "gray-mediumdark":    $gray-mediumdark,
  "gray-medium":        $gray-medium,
  "gray-light":         $gray-light,
  "gray-lighter":       $gray-lighter,
  "white":              $white,
  "blue":               $blue,
  "blue-mediumdark":    $blue-mediumdark,
  "blue-dark":          $blue-dark,
  "orange":             $orange,
  "gold":               $gold,
  "orange-dark":        $orange-dark,
  "gold-dark":          $gold-dark,
  "blue-app":           $blue-app,
  "blue-desktop":       $blue-desktop,
  "blue-desktop-light": $blue-desktop-light,
  "gray":               $gray,
  "twitter":            $twitter,
  "facebook":           $facebook,
  "whatsapp":           $whatsapp
);
// -

// Define as cores do tema
$primary:       $blue;
$secondary:     $gray-medium;
$success:       $green;
$info:          $cyan;
$warning:       $orange;
$danger:        $red;
$light:         $gray-lighter;
$dark:          $black;
// -

// Variáveis auxiliares:
$cx-border-color: $gray-light;
$cx-disabled-color: $gray-medium;
$cx-disabled-bg: $gray-lighter;

// TODO: definir o que vai entrar no tema
// Map do tema
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "blue-mediumdark":  $blue-mediumdark,
  "blue-dark":  $blue-dark,
  "gold":       $gold
);
// -

// Ajustes gerais
// Prefixo para as variáveis css :root
$variable-prefix: cx-;  // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:          $variable-prefix !default;

// Valor que determina a cor da fonte com base na cor de fundo
$min-contrast-ratio: 2;

// Body
/* $body-color: $black; */
$font-size-base: 1rem !default;
// -

// Headings
/* $h1-font-size: $font-size-base * 2; */
/* $h2-font-size: math.div($font-size-base * 4, 3); */
// $h2-font-size: $font-size-base * 4/3;
/* $h3-font-size: math.div($font-size-base * 5, 4); */
// $h3-font-size: $font-size-base * 5/4;
/* $h4-font-size: math.div($font-size-base * 6, 5); */
// $h4-font-size: $font-size-base * 6/5;
/* $h5-font-size: math.div($font-size-base * 7, 6); */
// $h5-font-size: $font-size-base * 7/6;
/* $h6-font-size: $font-size-base !default; */
// -

// Links
$link-shade-percentage: 10%;
$link-color: $blue;
// -

// Buttons
$btn-padding-y:               .46875rem;
$btn-padding-x:               .75rem;
$btn-font-size:               .875rem;

$btn-padding-y-sm:            .34375rem;
$btn-padding-x-sm:            .5rem;
$btn-font-size-sm:            .75rem;

$btn-padding-y-lg:            .59375rem;
$btn-padding-x-lg:            1rem;
$btn-font-size-lg:            1.125rem;

$btn-font-weight:             $font-weight-semibold;

// -

// Forms
/* $form-label-color: $gray-mediumdark;
$form-check-input-border: 1px solid $gray-medium;
$form-select-disabled-color: $cx-disabled-color;
$form-select-disabled-bg: $cx-disabled-bg;
$form-file-button-hover-bg: $gray-light;
$form-floating-label-opacity: 1; */
$form-label-margin-bottom: .25rem;

$form-feedback-valid-color:         $success !default;
$form-feedback-invalid-color:       $danger !default;

$form-feedback-icon-valid-color:    $form-feedback-valid-color !default;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='none'><path stroke='#{$form-feedback-icon-valid-color} ' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M3.5,12.5 l6,6 l11,-11' /></svg>"); // ícone oficial cx

$form-feedback-icon-invalid-color:  $form-feedback-invalid-color !default;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='none'><path stroke='#{$form-feedback-icon-invalid-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3,19.5 v.5 h18 v-.5 l-8.75,-14.5 h-.5z    M12,10 v4' /> <circle fill='#{$form-feedback-icon-invalid-color}' cx='12' cy='17' r='1' /></svg>"); // ícone oficial cx
// -

// component icons 
$form-select-indicator-color:       $gray-800 !default;
$navbar-dark-color:                 rgba($white, .55) !default;
$navbar-light-color:                rgba(var(--#{$prefix}emphasis-color-rgb), .65) !default;
$accordion-icon-color:              $primary; // padrão cx
$accordion-icon-active-color:       shade-color($primary, 10%); //sub variable
$carousel-control-color:            $white !default;
$btn-close-color:                   $black !default;

$form-select-indicator:       url("data:image/svg+xml,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='none'><path stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M3.5,8.5 l8.5,8.5 l8.5,-8.5' /></svg>"); // ícone oficial cx

$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='#{$navbar-dark-color} '><path d='M4,4 h16 a1,1 0 0 1 1,1 v1 a-1,1 0 0 1 -1,1 h-16 a-1,-1 0 0 1 -1,-1 v-1 a1,-1 0 0 1 1,-1z  M4,11 h16 a1,1 0 0 1 1,1 v1 a-1,1 0 0 1 -1,1 h-16 a-1,-1 0 0 1 -1,-1 v-1 a1,-1 0 0 1 1,-1z  M4,18 h16 a1,1 0 0 1 1,1 v1 a-1,1 0 0 1 -1,1 h-16 a-1,-1 0 0 1 -1,-1 v-1 a1,-1 0 0 1 1,-1z' /></svg>"); // ícone oficial cx

$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='#{$navbar-light-color}'><path d='M4,4 h16 a1,1 0 0 1 1,1 v1 a-1,1 0 0 1 -1,1 h-16 a-1,-1 0 0 1 -1,-1 v-1 a1,-1 0 0 1 1,-1z  M4,11 h16 a1,1 0 0 1 1,1 v1 a-1,1 0 0 1 -1,1 h-16 a-1,-1 0 0 1 -1,-1 v-1 a1,-1 0 0 1 1,-1z  M4,18 h16 a1,1 0 0 1 1,1 v1 a-1,1 0 0 1 -1,1 h-16 a-1,-1 0 0 1 -1,-1 v-1 a1,-1 0 0 1 1,-1z' /></svg>"); // ícone oficial cx

$accordion-button-icon: url("data:image/svg+xml,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='none'><path stroke='#{$accordion-icon-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M3.5,8.5 l8.5,8.5 l8.5,-8.5' /></svg>"); // ícone oficial cx

$accordion-button-active-icon:  url("data:image/svg+xml,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='none'><path stroke='#{$accordion-icon-active-color} ' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M3.5,8.5 l8.5,8.5 l8.5,-8.5' /></svg>"); // ícone oficial cx

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='none'><path stroke='#{$carousel-control-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M15.5,3.5 l-8.5,8.5 l8.5,8.5' /></svg>"); // ícone oficial cx
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='none'><path stroke='#{$carousel-control-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M8.5,3.5 l8.5,8.5 l-8.5,8.5' /></svg>"); // ícone oficial cx

$btn-close-bg:    url("data:image/svg+xml,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='none'><path stroke='#{$btn-close-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4.5,5.5 l14,14 M4.5,19.5 l14,-14' /></svg>"); // ícone oficial cx


// Inputs
/* $input-disabled-bg: $cx-disabled-bg;
$input-disabled-color: $cx-disabled-color; */
// -

// Tables
/* $table-striped-bg: $cx-disabled-bg; */
// -

// Accordion
/* $accordion-border-color: $cx-border-color; */
// $accordion-button-color: $accordion-color;
$accordion-button-active-bg: var(--cx-secondary-bg);
/* $accordion-color: $primary; */
// -

// Card
/* $card-border-color: $cx-border-color; */
/* $card-cap-bg: $gray-lighter; */
// -

// List group
/* $list-group-border-color: $cx-border-color;
$list-group-disabled-color: $gray-medium; */
// -

// Modal
/* $modal-content-border-color: $cx-border-color; */
// -

// Nav
/* $nav-tabs-border-color: $cx-border-color; */
/* $nav-link-disabled-color: $cx-disabled-color; */
/* $nav-tabs-link-active-color: $dark; */
// -

// Popover
/* $popover-header-bg: $gray-lighter; */
/* $popover-border-color: $cx-border-color; */
// -

// Progress
/* $progress-bg: $gray-lighter; */
// -

// Toast
/* $toast-header-color: $gray-mediumdark; */
/* $toast-header-background-color: $white; */
/* $toast-border-color: $cx-border-color; */
/* $toast-header-border-color: $toast-border-color; */
/* $toast-background-color: $gray-lighter; */
// -

// Close
$btn-close-color: $primary;
// TODO: definir a opacidade do botão e :hover
$btn-close-opacity:          .5 !default;
$btn-close-hover-opacity:    .75 !default;
// -

$lead-font-weight: normal;
$mark-bg: $gold;
$link-decoration: none;

// Importa o restante das variáveis Bootstrap
// (as que não foram declaradas neste arquivo)
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";
@import "variables-dark";

// Importa os mixins Bootstrap/Centralx
// Desta forma conseguimos alterar o comportamento de componentes
// individualmente sem ter que alterar o fonte do Bootstrap.
@import 'mixins';

// Importa o novo 'maps' introduzido no Bootstrap 5.2
@import "../node_modules/bootstrap/scss/maps";

// Importa o restante dos componentes Bootstrap
// Configuration
@import "../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
@import "alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/offcanvas";
@import "../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../node_modules/bootstrap/scss/utilities/api";

// Customizações pontuais
// Forms
.form-control:disabled, .form-control:read-only,
.form-control:disabled::placeholder, .form-control:read-only::placeholder {
  /* color: var(--cx-gray-medium);
  opacity: 1; */
}
.form-control:disabled::file-selector-button,
.form-control:disabled::-webkit-file-upload-button{
  /* color: var(--cx-gray-medium); */
}
.form-floating > label {
  /* color: var(--cx-gray-mediumdark); */
}
// -

// Nav
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  font-weight: bold;
}
// -

// Popover
// TODO: Fix temporário para o problema reportado em 
// https://github.com/twbs/bootstrap/issues/34005
.popover-header {
  border-bottom: $popover-border-width solid $popover-border-color;
}
// -
